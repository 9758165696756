import axios, { AxiosError, AxiosInstance, AxiosResponse, InternalAxiosRequestConfig } from 'axios';
import { getCookie, setCookie, deleteCookie } from '../utils/cookieUtils';

let isRefreshing = false;
let refreshSubscribers: ((token: string) => void)[] = [];

export const axiosInstance: AxiosInstance = axios.create({
  baseURL: process.env.REACT_APP_API_BASE_URL,
  timeout: 10000,
  headers: {
    'Content-Type': 'application/json',
  },
});

axiosInstance.interceptors.request.use(
  (config: InternalAxiosRequestConfig) => {
    // Reset password endpoint'i için özel kontrol
    if (config.url?.includes('/reset-password')) {
      // Reset password için header zaten eklenmiş ise kullan
      if (config.headers['Authorization']) {
        return config;
      }
    } else {
      // Diğer tüm istekler için normal token akışı
      const token = getCookie('token');
      if (token) {
        config.headers['Authorization'] = `Bearer ${token}`;
      }
    }
    return config;
  },
  (error: AxiosError) => Promise.reject(error)
);

axiosInstance.interceptors.response.use(
  (response: AxiosResponse) => response,
  async (error: AxiosError) => {
    const originalRequest = error.config as InternalAxiosRequestConfig & { _retry?: boolean };
    
    // Login endpoint'i için özel kontrol
    if (error.response?.status === 401 && originalRequest.url === '/api/v1/login') {
      return Promise.reject(error); // Login hatalarını direkt olarak reddet
    }
    
    if (error.response?.status === 401 && !originalRequest._retry) {
      if (isRefreshing) {
        return new Promise((resolve) => {
          refreshSubscribers.push((token: string) => {
            originalRequest.headers['Authorization'] = `Bearer ${token}`;
            resolve(axiosInstance(originalRequest));
          });
        });
      }

      originalRequest._retry = true;
      isRefreshing = true;

      try {
        const refreshToken = getCookie('refresh_token');
        if (!refreshToken) {
          return Promise.reject(new Error('No refresh token available'));
        }
        const response = await axiosInstance.post('/api/v1/refresh-token', { refresh_token: refreshToken });
        const { token, refresh_token } = response.data;

        setCookie('token', token, { secure: true, sameSite: 'Strict' });
        setCookie('refresh_token', refresh_token, { secure: true, sameSite: 'Strict' });
        
        axiosInstance.defaults.headers.common['Authorization'] = `Bearer ${token}`;
        refreshSubscribers.forEach(callback => callback(token));
        refreshSubscribers = [];

        return axiosInstance(originalRequest);
      } catch (refreshError) {
        deleteCookie('token');
        deleteCookie('refresh_token');
        window.location.href = '/giris';
        return Promise.reject(refreshError);
      } finally {
        isRefreshing = false;
      }
    }

    return Promise.reject(error);
  }
);

export default axiosInstance;
