import { createAction } from '@reduxjs/toolkit';
import { IFetchProductsRequest, IProductsResponse } from './products.d';

export enum ProductActionTypes {
  FETCH_PRODUCTS_REQUEST = '[Products] Fetch Products Request',
  FETCH_PRODUCTS_SUCCESS = '[Products] Fetch Products Success',
  FETCH_PRODUCTS_FAILURE = '[Products] Fetch Products Failure'
}

export const fetchProductsRequest = createAction<IFetchProductsRequest>(ProductActionTypes.FETCH_PRODUCTS_REQUEST);
export const fetchProductsSuccess = createAction<IProductsResponse>(ProductActionTypes.FETCH_PRODUCTS_SUCCESS);
export const fetchProductsFailure = createAction<string>(ProductActionTypes.FETCH_PRODUCTS_FAILURE); 