export enum DashboardActionTypes {
  FETCH_DASHBOARD_DATA = '[Dashboard] Fetch Dashboard Data',
  SET_DASHBOARD_DATA = '[Dashboard] Set Dashboard Data',
  DASHBOARD_ERROR = '[Dashboard] Dashboard Error',
}

interface IFetchDashboardDataAction {
  type: typeof DashboardActionTypes.FETCH_DASHBOARD_DATA;
}

interface ISetDashboardDataAction {
  type: typeof DashboardActionTypes.SET_DASHBOARD_DATA;
  payload: any; // Replace 'any' with your dashboard data type
}

interface IDashboardErrorAction {
  type: typeof DashboardActionTypes.DASHBOARD_ERROR;
  payload: { error: string };
}

export const fetchDashboardDataActionCreator = (): IFetchDashboardDataAction => ({
  type: DashboardActionTypes.FETCH_DASHBOARD_DATA,
});

export const setDashboardDataActionCreator = (data: any): ISetDashboardDataAction => ({
  type: DashboardActionTypes.SET_DASHBOARD_DATA,
  payload: data,
});

export const dashboardErrorActionCreator = (error: string): IDashboardErrorAction => ({
  type: DashboardActionTypes.DASHBOARD_ERROR,
  payload: { error },
});

export type DashboardActions =
  | IFetchDashboardDataAction
  | ISetDashboardDataAction
  | IDashboardErrorAction;
