import { call, put, takeLatest } from 'redux-saga/effects';
import { 
  ProductDetailActionTypes, 
  fetchProductDetailSuccess, 
  fetchProductDetailFailure,
  fetchProductDetailRequest 
} from './productDetail.action';
import { ProductDetailService } from './productDetail.service';

function* fetchProductDetailSaga(action: ReturnType<typeof fetchProductDetailRequest>): Generator {
  try {
    const response = yield call(
      [ProductDetailService, 'getProductDetail'], 
      action.payload
    );
    yield put(fetchProductDetailSuccess(response as any));
  } catch (error) {
    yield put(fetchProductDetailFailure(error as string));
  }
}

export function* productDetailWatcher() {
  yield takeLatest(ProductDetailActionTypes.FETCH_PRODUCT_DETAIL_REQUEST, fetchProductDetailSaga);
} 