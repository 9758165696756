import styled from 'styled-components';
import { Card } from 'antd';

export const ProductDetailContainer = styled.div`
  padding: ${({ theme }) => theme.spacings.medium};
  background-color: ${({ theme }) => theme.colors.background};
  min-width: 0;
`;

export const ProductDetailCard = styled(Card)`
  width: 100%;
  max-width: 800px;
  margin: 0 auto;
`;

export const ProductDetailSection = styled.div`
  margin-bottom: ${({ theme }) => theme.spacings.medium};
`;

export const ProductDetailLabel = styled.div`
  font-weight: ${({ theme }) => theme.fonts.weights.semiBold};
  color: ${({ theme }) => theme.colors.textSecondary};
  margin-bottom: ${({ theme }) => theme.spacings.small};
`;

export const ProductDetailValue = styled.div`
  color: ${({ theme }) => theme.colors.text};
  font-size: ${({ theme }) => theme.fontSizes.medium};
`;

export const PerformanceCard = styled(Card)`
  margin-bottom: ${({ theme }) => theme.spacings.medium};
  background-color: ${({ theme }) => theme.colors.background};
  border: 1px solid ${({ theme }) => theme.colors.divider};
  border-radius: ${({ theme }) => theme.borderRadius};
  
  .ant-card-body {
    padding: ${({ theme }) => theme.spacings.small};
  }
`;

export const PerformanceGrid = styled.div`
  display: grid;
  gap: ${({ theme }) => theme.spacings.small};
  grid-template-columns: repeat(3, 1fr);
  
  @media (max-width: ${({ theme }) => theme.breakpoints.tablet}) {
    grid-template-columns: 1fr;
  }
`;

export const ChannelDistribution = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${({ theme }) => theme.spacings.xsmall};
  padding: ${({ theme }) => theme.spacings.small};
  background-color: ${({ theme }) => theme.colors.white};
  border-radius: ${({ theme }) => theme.borderRadius};
  box-shadow: 0 1px 2px rgba(0, 0, 0, 0.05);
`;

export const MetricItem = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${({ theme }) => theme.spacings.xsmall};
  padding: ${({ theme }) => theme.spacings.small};
  background-color: ${({ theme }) => theme.colors.white};
  border-radius: ${({ theme }) => theme.borderRadius};
  box-shadow: 0 1px 2px rgba(0, 0, 0, 0.05);
`;

export const MetricLabel = styled.span`
  font-size: ${({ theme }) => theme.fontSizes.small};
  color: ${({ theme }) => theme.colors.textSecondary};
  font-weight: ${({ theme }) => theme.fonts.weights.medium};
`;

export const MetricValue = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: ${({ theme }) => theme.spacings.small};

  .ant-progress {
    flex: 1;
    margin: 0 ${({ theme }) => theme.spacings.small};
  }

  .ant-badge-count {
    background-color: ${({ theme }) => theme.colors.primary};
  }
`;

export const ProductImage = styled.div`
  width: 100%;
  height: 400px;
  background-color: ${({ theme }) => theme.colors.white};
  border-radius: ${({ theme }) => theme.borderRadius};
  overflow: hidden;
  
  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
`;

export const ProductInfo = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${({ theme }) => theme.spacings.medium};
  padding: ${({ theme }) => theme.spacings.medium};
  background-color: ${({ theme }) => theme.colors.background};
  border-radius: ${({ theme }) => theme.borderRadius};
`;

export const ProductTitle = styled.h1`
  font-size: ${({ theme }) => theme.fontSizes.xxlarge};
  color: ${({ theme }) => theme.colors.text};
  margin: 0;
`;

export const ProductDescription = styled.p`
  color: ${({ theme }) => theme.colors.textSecondary};
  font-size: ${({ theme }) => theme.fontSizes.medium};
  line-height: 1.6;
`;

export const PriceSection = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: ${({ theme }) => theme.spacings.medium} 0;
`;

export const OptionsSection = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${({ theme }) => theme.spacings.medium};
`;

export const ColorOption = styled.div`
  display: flex;
  gap: ${({ theme }) => theme.spacings.small};
`;

export const QuantitySection = styled.div`
  display: flex;
  align-items: center;
  gap: ${({ theme }) => theme.spacings.small};
`;

export const ActionButtons = styled.div`
  display: flex;
  gap: ${({ theme }) => theme.spacings.medium};
  margin-top: ${({ theme }) => theme.spacings.medium};
`;

export const TabSection = styled.div`
  margin-top: ${({ theme }) => theme.spacings.large};
`;

export const FeatureList = styled.ul`
  list-style-type: disc;
  padding-left: ${({ theme }) => theme.spacings.large};
  color: ${({ theme }) => theme.colors.textSecondary};
`;

export const ChannelGrid = styled.div`
  display: grid;
  gap: ${({ theme }) => theme.spacings.medium};
  grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
`;

export const ShippingInfo = styled.div`
  display: flex;
  align-items: flex-start;
  gap: ${({ theme }) => theme.spacings.medium};
  padding: ${({ theme }) => theme.spacings.medium};
`;

export const ProductDetailHeader = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: ${({ theme }) => theme.spacings.large};
`;

export const ProductDetailContent = styled.div`
  display: grid;
  grid-template-columns: 1fr;
  gap: ${({ theme }) => theme.spacings.medium};
  background-color: ${({ theme }) => theme.colors.white};
  padding: ${({ theme }) => theme.spacings.medium};
  border-radius: ${({ theme }) => theme.borderRadius};
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.1);
  
  @media (min-width: ${({ theme }) => theme.breakpoints.tablet}) {
    grid-template-columns: 1fr 1fr;
  }
`;

export const ProductFeatures = styled.div`
  margin-top: ${({ theme }) => theme.spacings.medium};
`;

export const FeatureItem = styled.div`
  display: flex;
  align-items: center;
  gap: ${({ theme }) => theme.spacings.small};
  margin-bottom: ${({ theme }) => theme.spacings.small};
  color: ${({ theme }) => theme.colors.textSecondary};
`;

export const ColorOptionItem = styled.div<{ $isSelected: boolean; $color: string }>`
  width: 32px;
  height: 32px;
  border-radius: 50%;
  background-color: ${({ $color }) => $color};
  border: 2px solid ${({ theme, $isSelected }) => 
    $isSelected ? theme.colors.primary : 'transparent'};
  cursor: pointer;
  transition: all 0.2s ease-in-out;

  &:hover {
    transform: scale(1.1);
  }
`;

export const StockBadge = styled.div<{ $inStock: boolean }>`
  padding: ${({ theme }) => `${theme.spacings.small} ${theme.spacings.medium}`};
  border-radius: ${({ theme }) => theme.borderRadius};
  background-color: ${({ theme, $inStock }) => 
    $inStock ? theme.colors.success + '20' : theme.colors.error + '20'};
  color: ${({ theme, $inStock }) => 
    $inStock ? theme.colors.success : theme.colors.error};
  font-weight: ${({ theme }) => theme.fonts.weights.medium};
  display: inline-flex;
  align-items: center;
  gap: ${({ theme }) => theme.spacings.small};
`;

export const ChannelPerformance = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${({ theme }) => theme.spacings.small};
`;

export const PerformanceMetric = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: ${({ theme }) => theme.spacings.small};
  background-color: ${({ theme }) => theme.colors.background};
  border-radius: ${({ theme }) => theme.borderRadius};
`;

export const MetricProgress = styled.div`
  flex: 1;
  margin: 0 ${({ theme }) => theme.spacings.medium};
`;

export const ImageSlider = styled.div`
  position: relative;
  width: 100%;
  height: 400px;
  background: ${({ theme }) => theme.colors.white};
  border-radius: ${({ theme }) => theme.borderRadius};
  overflow: hidden;
`;

export const SlideImage = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: opacity 0.3s ease-in-out;
`;

export const SliderNavigation = styled.div`
  position: absolute;
  bottom: 20px;
  left: 50%;
  transform: translateX(-50%);
  display: flex;
  gap: 8px;
`;

export const SliderDot = styled.button<{ $active: boolean }>`
  width: 8px;
  height: 8px;
  border-radius: 50%;
  background: ${({ theme, $active }) => 
    $active ? theme.colors.primary : theme.colors.divider};
  border: none;
  padding: 0;
  cursor: pointer;
  transition: all 0.2s ease-in-out;

  &:hover {
    transform: scale(1.2);
  }
`;

export const SliderArrow = styled.button<{ $direction: 'left' | 'right' }>`
  position: absolute;
  top: 50%;
  ${({ $direction }) => $direction}: 20px;
  transform: translateY(-50%);
  background: ${({ theme }) => theme.colors.white};
  border: none;
  border-radius: 50%;
  width: 40px;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.15);
  transition: all 0.2s ease-in-out;

  &:hover {
    transform: translateY(-50%) scale(1.1);
  }
`;

export const ThumbnailContainer = styled.div`
  display: flex;
  gap: 8px;
  margin-top: 16px;
  overflow-x: auto;
  padding-bottom: 8px;

  &::-webkit-scrollbar {
    height: 4px;
  }

  &::-webkit-scrollbar-track {
    background: ${({ theme }) => theme.colors.background};
  }

  &::-webkit-scrollbar-thumb {
    background: ${({ theme }) => theme.colors.divider};
    border-radius: 2px;
  }
`;

export const Thumbnail = styled.div<{ $active: boolean }>`
  width: 60px;
  height: 60px;
  display: flex;
  align-items: center;
  justify-content: center;
  background: ${({ theme }) => theme.colors.white};
  border-radius: 4px;
  cursor: pointer;
  border: 2px solid ${({ theme, $active }) => 
    $active ? theme.colors.primary : 'transparent'};
  transition: all 0.2s ease-in-out;

  &:hover {
    transform: scale(1.05);
  }
`;

export const IconDisplay = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  background: ${({ theme }) => theme.colors.white};
`;