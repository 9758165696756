import React from 'react';
import { StyledTable } from './transactions.styled';

const dataSource = [
  { key: '1', date: '2021-09-01', amount: '$120.00', status: 'Completed' },
  { key: '2', date: '2021-09-02', amount: '$80.00', status: 'Pending' },
  // Add more data as needed
];

const columns = [
  { title: 'Date', dataIndex: 'date', key: 'date' },
  { title: 'Amount', dataIndex: 'amount', key: 'amount' },
  { title: 'Status', dataIndex: 'status', key: 'status' },
];

const TransactionsTable: React.FC = () => <StyledTable dataSource={dataSource} columns={columns} pagination={false} />;

export default TransactionsTable;
