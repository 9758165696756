import React, { useEffect, useState, useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { useParams, useNavigate } from 'react-router-dom';
import { 
  Badge, 
  Progress, 
  Button, 
  Input, 
  Radio, 
  Tabs,
  Tooltip,
  Skeleton,
  Card
} from 'antd';
import { 
  ArrowLeftOutlined,
  ShoppingCartOutlined,
  HeartOutlined,
  TruckOutlined,
  CheckCircleOutlined,
  BarChartOutlined,
  DollarOutlined,
  MinusOutlined,
  PlusOutlined,
  LeftOutlined,
  RightOutlined
} from '@ant-design/icons';
import { fetchProductDetailRequest } from './productDetail.action';
import * as S from './productDetail.styled';
import { IGlobalState } from '../../../redux/reducers';

const { TabPane } = Tabs;

const channels = [
  { id: 'trendyol', name: 'Trendyol' },
  { id: 'hepsiburada', name: 'Hepsiburada' },
  { id: 'amazon', name: 'Amazon' },
  { id: 'n11', name: 'N11' },
] as const;

const colors = [
  { id: 'black', name: 'Siyah', value: '#000000' },
  { id: 'silver', name: 'Gümüş', value: '#C0C0C0' },
  { id: 'gold', name: 'Altın', value: '#FFD700' },
];

const ProductDetailComponent: React.FC = React.memo(() => {
  const { id } = useParams<{ id: string }>();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { t } = useTranslation(['productDetail', 'products']);
  
  const [selectedColor, setSelectedColor] = useState('black');
  const [quantity, setQuantity] = useState(1);
  const [currentImage, setCurrentImage] = useState(0);

  const { product, loading, error } = useSelector(
    (state: IGlobalState) => ({
      product: state.productDetail.product,
      loading: state.productDetail.loading,
      error: state.productDetail.error,
    })
  );

  useEffect(() => {
    if (id) {
      dispatch(fetchProductDetailRequest(Number(id)));
    }
  }, [dispatch, id]);

  const handleBack = useCallback(() => {
    navigate('/products');
  }, [navigate]);

  const handleColorSelect = useCallback((colorId: string) => {
    setSelectedColor(colorId);
  }, []);

  const handleQuantityChange = useCallback((value: number) => {
    if (product) {
      setQuantity(Math.max(1, Math.min(value, product.stock)));
    }
  }, [product]);

  const handlePrevImage = useCallback(() => {
    if (product?.images?.length) {
      setCurrentImage(prev => 
        prev === 0 ? (product.images?.length || 1) - 1 : prev - 1
      );
    }
  }, [product]);

  const handleNextImage = useCallback(() => {
    if (product?.images?.length) {
      setCurrentImage(prev => 
        prev === (product.images?.length || 1) - 1 ? 0 : prev + 1
      );
    }
  }, [product]);

  if (loading) {
    return (
      <S.ProductDetailContainer>
        <Skeleton active paragraph={{ rows: 10 }} />
      </S.ProductDetailContainer>
    );
  }

  if (error || !product) {
    return (
      <S.ProductDetailContainer>
        <div role="alert">{t('errors.fetchError')}</div>
      </S.ProductDetailContainer>
    );
  }

  const totalStock = product.channels.reduce((sum, channel) => sum + channel.stock, 0) + product.stock;
  const salesPerformance = 85; // Örnek değer

  return (
    <S.ProductDetailContainer>
      <S.ProductDetailHeader>
        <Button icon={<ArrowLeftOutlined />} onClick={handleBack}>
          {t('navigation.back')}
        </Button>
      </S.ProductDetailHeader>

      <S.PerformanceCard>
        <S.PerformanceGrid>
          <S.MetricItem>
            <S.MetricLabel>{t('metrics.totalStock')}</S.MetricLabel>
            <S.MetricValue>
              <Badge count={totalStock} showZero />
              <Progress percent={(totalStock / 100) * 100} size="small" />
            </S.MetricValue>
          </S.MetricItem>

          <S.MetricItem>
            <S.MetricLabel>{t('metrics.salesPerformance')}</S.MetricLabel>
            <S.MetricValue>
              <Badge count={`${salesPerformance}%`} style={{ backgroundColor: '#52c41a' }} />
              <Progress percent={salesPerformance} size="small" status="active" />
            </S.MetricValue>
          </S.MetricItem>

          <S.ChannelDistribution>
            <S.MetricLabel>{t('metrics.channelDistribution')}</S.MetricLabel>
            {product.channels.map(channel => (
              <S.MetricValue key={channel.id}>
                <span>{channels.find(c => c.id === channel.id)?.name}</span>
                <Progress 
                  percent={(channel.stock / totalStock) * 100} 
                  size="small"
                  showInfo={false}
                />
              </S.MetricValue>
            ))}
          </S.ChannelDistribution>
        </S.PerformanceGrid>
      </S.PerformanceCard>

      <S.ProductDetailContent>
        <div>
          <S.ImageSlider>
            {product.images && (
              <>
                <S.SlideImage>
                  {product.images[currentImage]}
                </S.SlideImage>
                <S.SliderArrow 
                  $direction="left" 
                  onClick={handlePrevImage}
                  aria-label={t('navigation.previousImage')}
                >
                  <LeftOutlined />
                </S.SliderArrow>
                <S.SliderArrow 
                  $direction="right" 
                  onClick={handleNextImage}
                  aria-label={t('navigation.nextImage')}
                >
                  <RightOutlined />
                </S.SliderArrow>
                <S.SliderNavigation>
                  {product.images.map((_, index) => (
                    <S.SliderDot
                      key={index}
                      $active={currentImage === index}
                      onClick={() => setCurrentImage(index)}
                      aria-label={t('navigation.goToImage', { number: index + 1 })}
                    />
                  ))}
                </S.SliderNavigation>
              </>
            )}
          </S.ImageSlider>
          <S.ThumbnailContainer>
            {product.images?.map((image, index) => (
              <S.Thumbnail
                key={index}
                $active={currentImage === index}
                onClick={() => setCurrentImage(index)}
              >
                {image}
              </S.Thumbnail>
            ))}
          </S.ThumbnailContainer>
        </div>

        <S.ProductInfo>
          <S.ProductTitle>{product.name}</S.ProductTitle>
          <Badge color={product.category === 'Elektronik' ? 'blue' : 'green'} text={product.category} />
          
          <S.ProductDescription>{product.description}</S.ProductDescription>

          <S.PriceSection>
            <div>
              <S.ProductDetailLabel>{t('labels.price')}</S.ProductDetailLabel>
              <S.ProductDetailValue>₺{product.price.toFixed(2)}</S.ProductDetailValue>
            </div>
            <S.StockBadge $inStock={product.stock > 0}>
              <CheckCircleOutlined />
              {product.stock > 0 ? t('stock.available') : t('stock.unavailable')}
            </S.StockBadge>
          </S.PriceSection>

          <S.OptionsSection>
            <div>
              <S.ProductDetailLabel>{t('options.color')}</S.ProductDetailLabel>
              <S.ColorOption>
                {colors.map(color => (
                  <Tooltip key={color.id} title={color.name}>
                    <S.ColorOptionItem
                      $color={color.value}
                      $isSelected={selectedColor === color.id}
                      onClick={() => handleColorSelect(color.id)}
                    />
                  </Tooltip>
                ))}
              </S.ColorOption>
            </div>

            <div>
              <S.ProductDetailLabel>{t('options.quantity')}</S.ProductDetailLabel>
              <S.QuantitySection>
                <Button
                  icon={<MinusOutlined />}
                  onClick={() => handleQuantityChange(quantity - 1)}
                  disabled={quantity <= 1}
                />
                <Input
                  type="number"
                  value={quantity}
                  onChange={(e) => handleQuantityChange(Number(e.target.value))}
                  style={{ width: 60, textAlign: 'center' }}
                />
                <Button
                  icon={<PlusOutlined />}
                  onClick={() => handleQuantityChange(quantity + 1)}
                  disabled={quantity >= (product?.stock || 0)}
                />
              </S.QuantitySection>
            </div>
          </S.OptionsSection>

          <S.ActionButtons>
            <Button
              type="primary"
              icon={<ShoppingCartOutlined />}
              size="large"
              block
            >
              {t('actions.addToCart')}
            </Button>
            <Button
              icon={<HeartOutlined />}
              size="large"
            >
              {t('actions.addToFavorites')}
            </Button>
          </S.ActionButtons>

          <S.TabSection>
            <Tabs defaultActiveKey="features">
              <TabPane tab={t('tabs.features')} key="features">
                <S.FeatureList>
                  {product.features?.map((feature, index) => (
                    <S.FeatureItem key={index}>
                      <CheckCircleOutlined />
                      <span>{feature}</span>
                    </S.FeatureItem>
                  ))}
                </S.FeatureList>
              </TabPane>

              <TabPane tab={t('tabs.channels')} key="channels">
                <S.ChannelGrid>
                  {product.channels.map(channel => (
                    <Card key={channel.id}>
                      <div style={{ display: 'flex', justifyContent: 'space-between', marginBottom: 16 }}>
                        <span>{channels.find(c => c.id === channel.id)?.name}</span>
                        <Badge
                          status={channel.active ? 'success' : 'error'}
                          text={channel.active ? t('status.active') : t('status.inactive')}
                        />
                      </div>
                      <div>
                        <div style={{ marginBottom: '12px' }}>
                          <div style={{ marginBottom: '4px' }}>
                            {t('channel.labels.price', { price: channel.price.toFixed(2) })}
                          </div>
                          <div style={{ marginBottom: '4px' }}>
                            {t('channel.labels.stock', { stock: channel.stock })}
                          </div>
                          <div style={{ marginBottom: '4px' }}>
                            {t('channel.labels.priceRule', { rule: channel.priceRule })}
                          </div>
                          <div style={{ marginBottom: '4px' }}>
                            {t('channel.labels.stockRule', { rule: channel.stockRule })}
                          </div>
                        </div>
                        <Button type="primary" block>
                          {t('channel.edit')}
                        </Button>
                      </div>
                    </Card>
                  ))}
                </S.ChannelGrid>
              </TabPane>

              <TabPane tab={t('tabs.shipping')} key="shipping">
                <S.ShippingInfo>
                  <TruckOutlined style={{ fontSize: 24 }} />
                  <div>
                    <p>{t('shipping.deliveryTime')}</p>
                    <p>{t('shipping.cost')}</p>
                  </div>
                </S.ShippingInfo>
              </TabPane>
            </Tabs>
          </S.TabSection>
        </S.ProductInfo>
      </S.ProductDetailContent>
    </S.ProductDetailContainer>
  );
});

ProductDetailComponent.displayName = 'ProductDetailComponent';

export const ProductDetail = React.lazy(() => Promise.resolve({ default: ProductDetailComponent }));