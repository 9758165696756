import React, { useEffect, useCallback, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { Table, Card, Skeleton, Input, Select, Button, Badge, Tooltip } from 'antd';
import { EditOutlined, FilterOutlined, SearchOutlined, PlusOutlined, InfoCircleOutlined, MoreOutlined, ClockCircleOutlined, CustomerServiceOutlined, DesktopOutlined } from '@ant-design/icons';
import type { TableProps, TablePaginationConfig } from 'antd';
import { fetchProductsRequest } from './products.action';
import { ProductsContainer, TableWrapper, HeaderSection, SearchSection } from './products.styled';
import { IProduct, IProductsState, IFetchProductsRequest, IChannel } from './products.d';
import { useNavigate } from 'react-router-dom';

const channels = [
  { id: 'trendyol', name: 'Trendyol' },
  { id: 'hepsiburada', name: 'Hepsiburada' },
  { id: 'amazon', name: 'Amazon' },
  { id: 'n11', name: 'N11' },
] as const;

const getProductIcon = (imageType: string, rotation: number = 0) => {
  const style = { fontSize: '64px', transform: `rotate(${rotation}deg)` };
  
  switch (imageType) {
    case 'watch':
      return <ClockCircleOutlined style={style} />;
    case 'headphone':
      return <CustomerServiceOutlined style={style} />;
    case 'desktop':
      return <DesktopOutlined style={style} />;
    default:
      return null;
  }
};

const ProductsComponent: React.FC = React.memo(() => {
  const dispatch = useDispatch();
  const { t } = useTranslation('products');
  const navigate = useNavigate();
  const [expandedRowKeys, setExpandedRowKeys] = useState<number[]>([]);
  
  const { products, loading } = useSelector((state: { products: IProductsState }) => ({
    products: state.products.products,
    loading: state.products.loading,
  }));

  const handleTableChange = useCallback((pagination: TablePaginationConfig) => {
    dispatch(fetchProductsRequest({
      page: pagination.current || 1,
      pageSize: pagination.pageSize || 10
    }));
  }, [dispatch]);

  useEffect(() => {
    dispatch(fetchProductsRequest({
      page: 1,
      pageSize: 10
    }));
  }, [dispatch]);

  const renderChannelCell = useCallback((channelData: IChannel | undefined, channelId: string) => {
    if (!channelData) {
      return (
        <Badge 
          status="default" 
          text={t('channel.cell.status.unlisted')}
        />
      );
    }

    return (
      <div 
        style={{ 
          backgroundColor: channelData.active ? 'rgba(82, 196, 26, 0.1)' : 'rgba(255, 77, 79, 0.1)',
          padding: '12px',
          borderRadius: '6px',
          textAlign: 'center'
        }}
      >
        <div>
          <Badge 
            status={channelData.active ? 'success' : 'error'} 
            text={channelData.active ? 
              t('channel.cell.status.active') : 
              t('channel.cell.status.inactive')} 
          />
        </div>
        <div style={{ 
          marginTop: '8px', 
          fontSize: '14px', 
          fontWeight: 'bold' 
        }}>
          ₺{channelData.price.toFixed(2)}
        </div>
        <div style={{ 
          fontSize: '12px', 
          color: '#666',
          marginTop: '4px'
        }}>
          ({channelData.stock})
        </div>
      </div>
    );
  }, [t]);

  const handleExpand = (expanded: boolean, record: IProduct) => {
    setExpandedRowKeys(expanded ? [record.id] : []);
  };

  const columns = useMemo(() => [
    {
      title: t('table.product'),
      dataIndex: 'name',
      key: 'name',
      fixed: 'left' as const,
      width: 200,
    },
    {
      title: t('table.category'),
      dataIndex: 'category',
      key: 'category',
      width: 120,
      render: (category: string) => (
        <Badge 
          color={category === 'Elektronik' ? 'blue' : 'green'} 
          text={category} 
        />
      ),
    },
    {
      title: t('table.basePrice'),
      dataIndex: 'price',
      key: 'price',
      width: 120,
      render: (price: number) => (
        <span>₺{price.toFixed(2)}</span>
      ),
    },
    {
      title: t('table.stock'),
      dataIndex: 'stock',
      key: 'stock',
      width: 80,
      align: 'center' as const,
    },
    ...channels.map(channel => ({
      title: t(`table.channels.${channel.id}`),
      key: channel.id,
      width: 150,
      render: (_: unknown, record: IProduct) => {
        const channelData = record.channels.find((c: IChannel) => c.id === channel.id);
        return renderChannelCell(channelData, channel.id);
      },
    })),
    {
      title: t('table.actions'),
      key: 'actions',
      fixed: 'right' as const,
      width: 120,
      render: (_: unknown, record: IProduct) => (
        <div style={{ display: 'flex', gap: '8px', justifyContent: 'flex-end' }}>
          <Tooltip title={t('actions.info')}>
            <Button 
              icon={<InfoCircleOutlined />} 
              type="text"
              onClick={() => handleExpand(!expandedRowKeys.includes(record.id), record)}
            />
          </Tooltip>
          <Tooltip title={t('actions.edit')}>
            <Button 
              icon={<EditOutlined />} 
              type="text"
              onClick={() => navigate(`/products/${record.id}`)}
            />
          </Tooltip>
          <Tooltip title={t('actions.more')}>
            <Button 
              icon={<MoreOutlined />} 
              type="text"
            />
          </Tooltip>
        </div>
      ),
    },
  ], [t, navigate, expandedRowKeys, handleExpand, renderChannelCell]);

  const renderExpandedRow = useCallback((record: IProduct) => (
    <div style={{ padding: '24px', backgroundColor: '#f5f5f5' }}>
      <h4 style={{ marginBottom: '16px', fontWeight: 'bold' }}>
        {t('table.expandedRow.channelDetails')}
      </h4>
      <div style={{ 
        display: 'grid', 
        gridTemplateColumns: 'repeat(4, 1fr)', 
        gap: '16px' 
      }}>
        {channels.map(channel => {
          const channelData = record.channels.find(c => c.id === channel.id);
          return (
            <div 
              key={channel.id} 
              style={{ 
                backgroundColor: 'white',
                padding: '16px',
                borderRadius: '8px',
                boxShadow: '0 1px 3px rgba(0,0,0,0.1)'
              }}
            >
              <div style={{ 
                display: 'flex', 
                justifyContent: 'space-between', 
                alignItems: 'center',
                marginBottom: '12px'
              }}>
                <span style={{ fontWeight: 'bold' }}>{channel.name}</span>
                <Badge 
                  status={channelData?.active ? 'success' : 'error'}
                  text={channelData?.active ? 
                    t('channel.status.active') : 
                    t('channel.status.inactive')}
                />
              </div>
              {channelData && (
                <>
                  <div style={{ marginBottom: '12px' }}>
                    <div style={{ marginBottom: '4px' }}>
                      {t('channel.labels.price')} ₺{channelData.price.toFixed(2)}
                    </div>
                    <div style={{ marginBottom: '4px' }}>
                      {t('channel.labels.stock')} {channelData.stock}
                    </div>
                    <div style={{ marginBottom: '4px' }}>
                      {t('channel.labels.priceRule')} {channelData.priceRule}
                    </div>
                    <div style={{ marginBottom: '4px' }}>
                      {t('channel.labels.stockRule')} {channelData.stockRule}
                    </div>
                  </div>
                  <Button type="primary" block>
                    {t('table.expandedRow.edit')}
                  </Button>
                </>
              )}
              {!channelData && (
                <div style={{ textAlign: 'center', padding: '20px 0' }}>
                  <div style={{ marginBottom: '12px', color: '#999' }}>
                    {t('table.expandedRow.notListedMessage')}
                  </div>
                  <Button type="primary">
                    {t('table.expandedRow.addToChannel')}
                  </Button>
                </div>
              )}
            </div>
          );
        })}
      </div>
    </div>
  ), [t]);

  const renderProductImages = (imageType: string) => {
    return [0, 90, 180, 270].map((rotation) => (
      getProductIcon(imageType, rotation)
    ));
  };

  return (
    <ProductsContainer>
      <HeaderSection>
        <h1>{t('header')}</h1>
        <SearchSection>
          <Input
            prefix={<SearchOutlined />}
            placeholder={t('search.placeholder')}
            aria-label={t('search.ariaLabel')}
          />
          <Select
            placeholder={t('category.placeholder')}
            style={{ width: 200 }}
            aria-label={t('category.ariaLabel')}
          >
            <Select.Option value="all">{t('category.all')}</Select.Option>
            <Select.Option value="electronics">{t('category.electronics')}</Select.Option>
            <Select.Option value="home">{t('category.home')}</Select.Option>
          </Select>
          <Button 
            icon={<FilterOutlined />}
            aria-label={t('filter.ariaLabel')}
          >
            {t('filter.button')}
          </Button>
          <Button 
            type="primary" 
            icon={<PlusOutlined />}
            aria-label={t('product.ariaLabel')}
          >
            {t('product.add')}
          </Button>
        </SearchSection>
      </HeaderSection>

      <TableWrapper>
        <Table<IProduct>
          dataSource={products}
          columns={columns}
          loading={loading}
          rowKey="id"
          expandable={{
            expandedRowRender: renderExpandedRow,
            expandedRowKeys: expandedRowKeys,
            onExpand: handleExpand,
          }}
          pagination={{
            total: products.length,
            pageSize: 10,
            showSizeChanger: true,
            showTotal: (total) => t('table.pagination.total', { total }),
            locale: {
              items_per_page: t('table.pagination.itemsPerPage'),
              jump_to: t('table.pagination.jumpTo'),
              page: t('table.pagination.page'),
              prev_page: t('table.pagination.prev'),
              next_page: t('table.pagination.next')
            }
          }}
          onChange={handleTableChange}
          scroll={{ x: 'max-content' }}
          sticky
        />
      </TableWrapper>
    </ProductsContainer>
  );
});

ProductsComponent.displayName = 'ProductsComponent';

export const Products = React.lazy(() => Promise.resolve({ default: ProductsComponent }));
