import { Col, Row } from 'antd';
import React from 'react';
import { StyledCard } from './statistics.styled';

const data = [
  { title: 'Total Sales', value: '$20,000' },
  { title: 'Orders', value: '200' },
  { title: 'Cancellations', value: '10' },
];

const StatisticsCards: React.FC = () => (
  <Row gutter={16}>
    {data.map(item => (
      <Col span={8} key={item.title}>
        <StyledCard>
          <h3>{item.title}</h3>
          <p>{item.value}</p>
        </StyledCard>
      </Col>
    ))}
  </Row>
);

export default StatisticsCards;
