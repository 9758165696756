import { IProductDetailResponse } from './productDetail.d';

export enum ProductDetailActionTypes {
  FETCH_PRODUCT_DETAIL_REQUEST = '[Product Detail] Fetch Product Detail Request',
  FETCH_PRODUCT_DETAIL_SUCCESS = '[Product Detail] Fetch Product Detail Success',
  FETCH_PRODUCT_DETAIL_FAILURE = '[Product Detail] Fetch Product Detail Failure',
}

export const fetchProductDetailRequest = (id: number) => ({
  type: ProductDetailActionTypes.FETCH_PRODUCT_DETAIL_REQUEST,
  payload: id,
});

export const fetchProductDetailSuccess = (response: IProductDetailResponse) => ({
  type: ProductDetailActionTypes.FETCH_PRODUCT_DETAIL_SUCCESS,
  payload: response,
});

export const fetchProductDetailFailure = (error: string) => ({
  type: ProductDetailActionTypes.FETCH_PRODUCT_DETAIL_FAILURE,
  payload: error,
}); 