import { UserOutlined } from '@ant-design/icons';
import { Avatar, List } from 'antd';
import React from 'react';
import { StyledList } from './activity-feed.styled';

const data: ActivityItem[] = [
  { title: 'New Order', description: 'You have a new order #12345', time: '2 mins ago' },
  { title: 'Order Cancelled', description: 'Order #12344 has been cancelled', time: '1 hour ago' },
  // Add more data as needed
];

const ActivityFeed: React.FC = () => (
  <StyledList
    itemLayout="horizontal"
    dataSource={data}
    renderItem={(item, index) => {
      const activityItem = item as ActivityItem;
      return (
        <List.Item>
          <List.Item.Meta avatar={<Avatar icon={<UserOutlined />} />} title={activityItem.title} description={activityItem.description} />
          <div>{activityItem.time}</div>
        </List.Item>
      );
    }}
  />
);

export default ActivityFeed;
