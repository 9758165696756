import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Form, Row, Col } from 'antd';
import { EditOutlined } from '@ant-design/icons';
import { useTranslation } from 'react-i18next';
import { motion } from 'framer-motion';
import { IGlobalState } from '../../../../redux/reducers';
import { IAccountSettingsState } from '../../accountSettings.reducer';
import { updateUserData, fetchUserData } from '../../accountSettings.actions';
import {
  StyledCard,
  SectionTitle,
  StyledForm,
  AnimatedSVG,
  GridContainer,
  InfoContainer,
  SectionTitleContainer
} from './myProfile.styled';
import { Button, H4 } from '../../../../components/Design/design.styled';
import { GenericInputLazy } from '../../../../components/GenericInput/genericInput.lazy';

const MyProfile: React.FC = () => {
  const { t } = useTranslation('accountSettings');
  const dispatch = useDispatch();
  const accountSettings = useSelector<IGlobalState, IAccountSettingsState>(state => state.ACCOUNT_SETTINGS);
  const [form] = Form.useForm();
  const [isEditing, setIsEditing] = useState(false);

  useEffect(() => {
    dispatch(fetchUserData());
  }, [dispatch]);

  useEffect(() => {
    if (accountSettings.user) {
      form.setFieldsValue(accountSettings.user);
    }
  }, [accountSettings.user, form]);

  const handleEdit = () => {
    if (isEditing) {
      form.validateFields().then(values => {
        dispatch(updateUserData(values));
        setIsEditing(false);
      });
    } else {
      setIsEditing(true);
    }
  };

  const cardVariants = {
    hidden: { opacity: 0, y: 20 },
    visible: { opacity: 1, y: 0, transition: { duration: 0.5 } },
  };

  const svgVariants = {
    hidden: { pathLength: 0 },
    visible: { pathLength: 1, transition: { duration: 2, ease: "easeInOut" } }
  };

  return (
    <StyledForm form={form} layout="vertical" onFinish={handleEdit}>
      <motion.div initial="hidden" animate="visible" variants={cardVariants}>
        <StyledCard>
          <GridContainer>
            <InfoContainer>
              <SectionTitleContainer>
                <SectionTitle>
                  <H4>{t('personalInformation')}</H4>
                </SectionTitle>
                <Button
                  $buttonstyle={isEditing ? 'secondary' : 'primary'}
                  $size="medium"
                  onClick={handleEdit}
                  $loading={accountSettings.loading}
                >
                  <EditOutlined /> {isEditing ? t('save') : t('edit')}
                </Button>
              </SectionTitleContainer>
              <Row gutter={24}>
                <Col span={12}>
                  <Form.Item name="name" label={t('firstName')}>
                    <GenericInputLazy
                      disabled={!isEditing}
                    />
                  </Form.Item>
                </Col>
                <Col span={12}>
                  <Form.Item name="surname" label={t('lastName')}>
                    <GenericInputLazy
                      disabled={!isEditing}
                    />
                  </Form.Item>
                </Col>
                <Col span={12}>
                  <Form.Item name="email" label={t('emailAddress')}>
                    <GenericInputLazy
                      disabled={!isEditing}
                      type="email"
                    />
                  </Form.Item>
                </Col>
                <Col span={12}>
                  <Form.Item name="phone" label={t('phone')}>
                    <GenericInputLazy
                      disabled={!isEditing}
                    />
                  </Form.Item>
                </Col>
                <Col span={24}>
                  <Form.Item name="bio" label={t('bio')}>
                    <GenericInputLazy
                      disabled={!isEditing}
                      type="textarea"
                    />
                  </Form.Item>
                </Col>
              </Row>
            </InfoContainer>
          </GridContainer>
        </StyledCard>
      </motion.div>

      <motion.div initial="hidden" animate="visible" variants={cardVariants}>
        <StyledCard>
          <SectionTitleContainer>
            <SectionTitle>
              <H4>{t('address')}</H4>
            </SectionTitle>
            <Button $buttonstyle="primary" $size="medium" onClick={() => setIsEditing(!isEditing)}>
              <EditOutlined /> {isEditing ? t('save') : t('edit')}
            </Button>
          </SectionTitleContainer>
          <Row gutter={24}>
            <Col span={12}>
              <Form.Item name="country" label={t('country')}>
                <GenericInputLazy
                  disabled={!isEditing}
                />
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item name="city" label={t('cityState')}>
                <GenericInputLazy
                  disabled={!isEditing}
                />
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item name="postalCode" label={t('postalCode')}>
                <GenericInputLazy
                  disabled={!isEditing}
                />
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item name="taxId" label={t('taxId')}>
                <GenericInputLazy
                  disabled={!isEditing}
                />
              </Form.Item>
            </Col>
          </Row>
          <AnimatedSVG
            width="100%"
            height="100"
            viewBox="0 0 100 100"
            initial="hidden"
            animate="visible"
          >
            <motion.path
              d="M10 90 Q 50 10 90 90"
              stroke="#1890ff"
              strokeWidth="2"
              fill="none"
              variants={svgVariants}
            />
          </AnimatedSVG>
        </StyledCard>
      </motion.div>
    </StyledForm>
  );
};

export default MyProfile;
