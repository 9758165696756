import styled from 'styled-components';

export const ProductsContainer = styled.div`
  padding: ${({ theme }) => theme.spacings.large};
  background-color: ${({ theme }) => theme.colors.background};
  min-width: 0;
`;

export const HeaderSection = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: ${({ theme }) => theme.spacings.large};
  flex-wrap: wrap;
  gap: ${({ theme }) => theme.spacings.medium};

  h1 {
    font-size: ${({ theme }) => theme.fontSizes.xlarge};
    color: ${({ theme }) => theme.colors.text};
  }

  @media (max-width: ${({ theme }) => theme.breakpoints.tablet}) {
    flex-direction: column;
    align-items: stretch;
  }
`;

export const SearchSection = styled.div`
  display: flex;
  gap: ${({ theme }) => theme.spacings.small};
  align-items: center;
  flex-wrap: wrap;

  @media (max-width: ${({ theme }) => theme.breakpoints.tablet}) {
    width: 100%;
    
    .ant-input-affix-wrapper,
    .ant-select,
    .ant-btn {
      flex: 1;
      min-width: 150px;
    }
  }
`;

export const TableWrapper = styled.div`
  background: ${({ theme }) => theme.colors.white};
  border-radius: ${({ theme }) => theme.borderRadius};
  box-shadow: ${({ theme }) => theme.shadows.card};
  overflow-x: auto;
  
  .ant-table-wrapper {
    min-width: 0;
  }

  .ant-table {
    overflow-x: auto;
  }
`;

export const ProductsGrid = styled.div`
  display: grid;
  gap: ${({ theme }) => theme.spacings.medium};
  
  @media (min-width: ${({ theme }) => theme.breakpoints.mobileL}) {
    grid-template-columns: repeat(1, 1fr);
  }

  @media (min-width: ${({ theme }) => theme.breakpoints.tablet}) {
    grid-template-columns: repeat(2, 1fr);
  }

  @media (min-width: ${({ theme }) => theme.breakpoints.laptop}) {
    grid-template-columns: repeat(3, 1fr);
  }
`; 