import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { IProductDetailState, IProductDetailResponse } from './productDetail.d';
import { ClockCircleOutlined } from '@ant-design/icons';
import React from 'react';

const dummyProduct = {
  id: 1,
  name: 'Akıllı Saat X3000',
  description: 'Yeni nesil akıllı saat, sağlık takibi ve bildirim özellikleri ile',
  category: 'Elektronik',
  price: 999.99,
  stock: 50,
  createdAt: '2024-03-15T10:30:00Z',
  updatedAt: '2024-03-15T10:30:00Z',
  status: 'active' as const,
  images: [
    React.createElement(ClockCircleOutlined, { 
      key: "1", 
      style: { fontSize: '64px' } 
    }),
    React.createElement(ClockCircleOutlined, { 
      key: "2", 
      style: { fontSize: '64px', transform: 'rotate(90deg)' } 
    }),
    React.createElement(ClockCircleOutlined, { 
      key: "3", 
      style: { fontSize: '64px', transform: 'rotate(180deg)' } 
    }),
    React.createElement(ClockCircleOutlined, { 
      key: "4", 
      style: { fontSize: '64px', transform: 'rotate(270deg)' } 
    })
  ],
  features: [
    'Yüksek çözünürlüklü AMOLED ekran',
    'Su geçirmez tasarım (5 ATM)',
    '7 gün pil ömrü',
    'Kalp ritmi ve oksijen seviyesi ölçümü',
    'GPS ve GLONASS desteği',
    '50+ spor modu'
  ],
  channels: [
    { 
      id: 'trendyol', 
      active: true, 
      price: 1049.99, 
      stock: 30,
      priceRule: '+10%',
      stockRule: 'max 30'
    },
    { 
      id: 'hepsiburada', 
      active: true, 
      price: 1029.99, 
      stock: 20,
      priceRule: '+5%',
      stockRule: '40%'
    },
    { 
      id: 'amazon', 
      active: false, 
      price: 1059.99, 
      stock: 0,
      priceRule: '+12%',
      stockRule: '50%'
    },
    { 
      id: 'n11', 
      active: true, 
      price: 1039.99, 
      stock: 15,
      priceRule: '-1%',
      stockRule: '30%'
    }
  ]
};

const initialState: IProductDetailState = {
  product: dummyProduct,
  loading: false,
  error: null,
};

const productDetailSlice = createSlice({
  name: 'productDetail',
  initialState,
  reducers: {
    fetchProductDetailRequest: (state) => {
      state.loading = true;
      state.error = null;
    },
    fetchProductDetailSuccess: (state, action: PayloadAction<IProductDetailResponse>) => {
      state.loading = false;
      state.product = dummyProduct;
      state.error = null;
    },
    fetchProductDetailFailure: (state, action: PayloadAction<string>) => {
      state.loading = false;
      state.error = action.payload;
    },
  },
});

export const {
  fetchProductDetailRequest,
  fetchProductDetailSuccess,
  fetchProductDetailFailure,
} = productDetailSlice.actions;

export default productDetailSlice.reducer; 