import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { IProductsState, IProductsResponse } from './products.d';

const initialState: IProductsState = {
  products: [
    {
      id: 1,
      name: 'Akıllı Saat X3000',
      category: 'Elektronik',
      price: 999.99,
      stock: 50,
      createdAt: '2024-03-15T10:30:00Z',
      status: 'active',
      imageType: 'watch',
      channels: [
        { 
          id: 'trendyol', 
          active: true, 
          price: 1049.99, 
          stock: 30,
          priceRule: '+10%',
          stockRule: 'max 30'
        },
        { 
          id: 'hepsiburada', 
          active: true, 
          price: 1029.99, 
          stock: 20,
          priceRule: '+5%',
          stockRule: '40%'
        },
        { 
          id: 'amazon', 
          active: false, 
          price: 1059.99, 
          stock: 0,
          priceRule: '+12%',
          stockRule: '50%'
        },
        { 
          id: 'n11', 
          active: true, 
          price: 1039.99, 
          stock: 15,
          priceRule: '-1%',
          stockRule: '30%'
        }
      ]
    },
    {
      id: 2,
      name: 'Kablosuz Kulaklık Pro',
      category: 'Elektronik',
      price: 599.99,
      stock: 100,
      createdAt: '2024-03-14T15:45:00Z',
      status: 'active',
      imageType: 'headphone',
      channels: [
        { 
          id: 'trendyol', 
          active: true, 
          price: 629.99, 
          stock: 50,
          priceRule: '+8%',
          stockRule: '50%'
        },
        { 
          id: 'hepsiburada', 
          active: true, 
          price: 619.99, 
          stock: 40,
          priceRule: '+3%',
          stockRule: '40%'
        },
        { 
          id: 'amazon', 
          active: true, 
          price: 639.99, 
          stock: 30,
          priceRule: '+7%',
          stockRule: '30%'
        },
        { 
          id: 'n11', 
          active: false, 
          price: 609.99, 
          stock: 0,
          priceRule: '+2%',
          stockRule: '20%'
        }
      ]
    },
    {
      id: 3,
      name: 'Ergonomik Ofis Koltuğu',
      category: 'Ev & Yaşam',
      price: 1499.99,
      stock: 30,
      createdAt: '2024-03-13T09:15:00Z',
      status: 'active',
      imageType: 'desktop',
      channels: [
        { 
          id: 'trendyol', 
          active: true, 
          price: 1549.99, 
          stock: 15,
          priceRule: '+5%',
          stockRule: '50%'
        },
        { 
          id: 'hepsiburada', 
          active: true, 
          price: 1529.99, 
          stock: 10,
          priceRule: '+2%',
          stockRule: '33%'
        },
        { 
          id: 'amazon', 
          active: true, 
          price: 1569.99, 
          stock: 5,
          priceRule: '+8%',
          stockRule: 'min 5'
        },
        { 
          id: 'n11', 
          active: true, 
          price: 1539.99, 
          stock: 8,
          priceRule: '+3%',
          stockRule: '25%'
        }
      ]
    }
  ],
  loading: false,
  error: null,
};

const productsSlice = createSlice({
  name: 'products',
  initialState,
  reducers: {
    fetchProductsRequest: (state) => {
      state.loading = true;
      state.error = null;
    },
    fetchProductsSuccess: (state, action: PayloadAction<IProductsResponse>) => {
      state.loading = false;
      state.products = action.payload.data;
      state.error = null;
    },
    fetchProductsFailure: (state, action: PayloadAction<string>) => {
      state.loading = false;
      state.error = action.payload;
    },
  },
});

export const { fetchProductsRequest, fetchProductsSuccess, fetchProductsFailure } = productsSlice.actions;
export default productsSlice.reducer; 