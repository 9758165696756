import styled from 'styled-components';
import { Card, Typography, Button, Form } from 'antd';
import { motion } from 'framer-motion';

const { Title } = Typography;

export const StyledCard = styled(Card)`
  margin-bottom: 24px;
  border-radius: 8px;
  box-shadow: ${props => props.theme.shadows.small};
  background-color: ${props => props.theme.colors.background};
  transition: all 0.3s ease;

  &:hover {
    transform: translateY(-5px);
    box-shadow: ${props => props.theme.shadows.medium};
  }
`;

export const SectionTitle = styled(Title)`
  margin-bottom: 24px !important;
  color: ${props => props.theme.colors.text} !important;
`;

export const EditButton = styled(Button)`
  float: right;
  background-color: ${props => props.theme.colors.primary};
  color: white;
  border: none;
  border-radius: 4px;
  padding: 4px 12px;
  font-size: 14px;
  transition: all 0.3s ease;

  &:hover {
    background-color: ${props => props.theme.colors.indigo600};
    box-shadow: 0 2px 8px rgba(0, 0, 0, 0.15);
  }

  &:active {
    background-color: ${props => props.theme.colors.indigo700};
    box-shadow: none;
  }
`;

export const StyledForm = styled(Form)`
  .ant-form-item-label > label {
    color: ${props => props.theme.colors.text};
  }

  .ant-input {
    background-color: ${props => props.theme.colors.background};
    color: ${props => props.theme.colors.text};
    border-color: ${props => props.theme.colors.divider};
    transition: all 0.3s ease;

    &:hover, &:focus {
      border-color: ${props => props.theme.colors.primary};
      box-shadow: 0 0 0 2px rgba(24, 144, 255, 0.2);
    }
  }

  .ant-input[disabled] {
    color: ${props => props.theme.colors.secondary};
    background-color: ${props => props.theme.colors.lightgrey};
  }
`;

export const AnimatedSVG = styled(motion.svg)`
  width: 100%;
  height: 100px;
  margin-top: 20px;
`;

export const GridContainer = styled.div`
  display: grid;
  grid-template-columns: 1fr;

  @media (max-width: 768px) {
    grid-template-columns: 1fr;
  }
`;

export const ProfilePicture = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;

  img {
    width: 150px;
    height: 150px;
    border-radius: 50%;
    object-fit: cover;
  }
`;

export const InfoContainer = styled.div`
  display: flex;
  flex-direction: column;
`;

export const SectionTitleContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 24px;
`;
