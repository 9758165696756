import { all } from 'redux-saga/effects';
import loginModuleSaga from '../../pages/Login/login.saga';
import { watchLanguageChange } from './languageSaga';
import { accountSettingsSaga } from '../../pages/AccountSettings/accountSettings.saga';
import { watchChannelsSaga } from '../../pages/Channels/channels.saga';
import { productsWatcher } from '../../pages/Products/products.saga';
import { productDetailWatcher } from '../../pages/Products/Detail/productDetail.saga';

export default function* rootSaga() {
	yield all([
		loginModuleSaga(),
		watchLanguageChange(),
		accountSettingsSaga(),
		watchChannelsSaga(),
		productsWatcher(),
		productDetailWatcher(),
		// ... other sagas
	]);
}
