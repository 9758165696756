import { call, put, takeLatest } from 'redux-saga/effects';
import { ProductActionTypes, fetchProductsSuccess, fetchProductsFailure, fetchProductsRequest } from './products.action';
import { ProductsService } from './products.service';
import { IProductsResponse } from './products.d';

function* fetchProductsSaga(action: ReturnType<typeof fetchProductsRequest>): Generator {
  try {
    const { page, pageSize } = action.payload;
    const response: IProductsResponse = yield call(ProductsService.getProducts, page, pageSize);
    yield put(fetchProductsSuccess(response));
  } catch (error) {
    yield put(fetchProductsFailure(error as string));
  }
}

export function* productsWatcher() {
  yield takeLatest(ProductActionTypes.FETCH_PRODUCTS_REQUEST, fetchProductsSaga);
} 