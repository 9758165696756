import React, { useState } from 'react';
import { BellOutlined, LogoutOutlined, SearchOutlined, UserOutlined, MenuOutlined } from '@ant-design/icons';
import { Avatar, Dropdown, Input, Menu, Switch, Drawer } from 'antd';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { logout } from '../../pages/Login/login.reducer';
import { SET_NOTIFICATION } from '../Notification/notification.actions';
import { 
  NotificationIcon, 
  SearchBarContainer, 
  StyledHeader, 
  SummaryContainer, 
  SummaryItem,
  MobileMenuButton,
  DesktopView,
  MobileView
} from './dashboard-header.styled';
import { useTranslation } from 'react-i18next';
import { toggleTheme } from '../../redux/reducers/theme.reducer';
import { IGlobalState } from '../../redux/reducers';

const { Search } = Input;

const HeaderComponent: React.FC = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { t } = useTranslation(['header', 'common']);
  const [mobileDrawerVisible, setMobileDrawerVisible] = useState(false);
  const isDarkMode = useSelector((state: IGlobalState) => state.theme.isDarkMode);

  const notifications = [
    { id: 1, message: t('header:notifications.newOrder'), time: t('header:notifications.twoMinsAgo') },
    { id: 2, message: t('header:notifications.orderShipped', { orderNumber: '12345' }), time: t('header:notifications.oneHourAgo') },
  ];

  const notificationMenu = (
    <Menu>
      {notifications.map(notification => (
        <Menu.Item key={notification.id}>
          {notification.message} - {notification.time}
        </Menu.Item>
      ))}
    </Menu>
  );

  const handleLogout = () => {
    dispatch(logout());
    document.cookie.split(";").forEach((c) => {
      document.cookie = c
        .replace(/^ +/, "")
        .replace(/=.*/, "=;expires=" + new Date().toUTCString() + ";path=/");
    });
    dispatch(SET_NOTIFICATION({
      message: t('header:logoutSuccess'),
      type: 'success',
      description: t('header:logoutSuccessDescription'),
    }));
    navigate('/login');
  };

  const userMenu = (
    <Menu>
      <Menu.Item key="logout" onClick={handleLogout} icon={<LogoutOutlined />}>
        {t('header:logout')}
      </Menu.Item>
    </Menu>
  );

  const renderSummary = () => (
    <SummaryContainer>
      <SummaryItem>
        <span>{t('header:totalSales')}</span>
        <strong>{t('header:currency', { amount: 20000 })}</strong>
      </SummaryItem>
      <SummaryItem>
        <span>{t('header:orders')}</span>
        <strong>200</strong>
      </SummaryItem>
      <SummaryItem>
        <span>{t('header:cancellations')}</span>
        <strong>10</strong>
      </SummaryItem>
    </SummaryContainer>
  );

  const handleThemeToggle = () => {
    dispatch(toggleTheme());
  };

  return (
    <StyledHeader>
      <DesktopView>
        <div className="left-section">
          <Dropdown overlay={userMenu} trigger={['click']}>
            <Avatar size="large" icon={<UserOutlined />} />
          </Dropdown>
          {renderSummary()}
        </div>
        <div className="right-section">
          <SearchBarContainer>
            <Search placeholder={t('header:searchPlaceholder')} prefix={<SearchOutlined />} />
          </SearchBarContainer>
          <Dropdown overlay={notificationMenu} trigger={['click']}>
            <NotificationIcon count={notifications.length}>
              <BellOutlined style={{ fontSize: '24px' }} />
            </NotificationIcon>
          </Dropdown>
          <Switch
            checked={isDarkMode}
            onChange={handleThemeToggle}
            checkedChildren="🌙"
            unCheckedChildren="☀️"
          />
        </div>
      </DesktopView>

      <MobileView>
        <MobileMenuButton onClick={() => setMobileDrawerVisible(true)}>
          <MenuOutlined />
        </MobileMenuButton>
        <Dropdown overlay={userMenu} trigger={['click']}>
          <Avatar size="large" icon={<UserOutlined />} />
        </Dropdown>
        <Drawer
          title={t('header:menu')}
          placement="left"
          onClose={() => setMobileDrawerVisible(false)}
          visible={mobileDrawerVisible}
        >
          <SearchBarContainer>
            <Search placeholder={t('header:searchPlaceholder')} prefix={<SearchOutlined />} />
          </SearchBarContainer>
          {renderSummary()}
          <Menu mode="vertical">
            <Menu.Item key="notifications" icon={<BellOutlined />}>
              {t('header:notifications')}
            </Menu.Item>
            <Menu.Item key="theme">
              <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
                {t('toggleTheme')}
                <Switch
                  checked={isDarkMode}
                  onChange={handleThemeToggle}
                  checkedChildren="🌙"
                  unCheckedChildren="☀️"
                  size="small"
                />
              </div>
            </Menu.Item>
            <Menu.Item key="logout" icon={<LogoutOutlined />} onClick={handleLogout}>
              {t('header:logout')}
            </Menu.Item>
          </Menu>
        </Drawer>
      </MobileView>
    </StyledHeader>
  );
};

export default HeaderComponent;
