import { axiosInstance } from '../../api/axios-config';
import { IProductsResponse } from './products.d';

export const ProductsService = {
  getProducts: (page: number, limit: number): Promise<IProductsResponse> => {
    return axiosInstance.get(`/api/v1/products`, {
      params: {
        page,
        limit,
      },
    });
  },
}; 