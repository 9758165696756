import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import ActivityFeed from './Internal/ActivityFeed';
import StatisticsCards from './Internal/Statistics';
import TransactionsTable from './Internal/Transactions';
import { fetchDashboardDataActionCreator } from './dashboard.action';
import { IGlobalState } from '../../redux/reducers';
import { IDashboardState } from './dashboard.reducer';
import { DashboardContainer, StyledCard, StyledTitle, GridContainer, FullWidthCard } from './dashboard.styled';

const Dashboard: React.FC = () => {
  const { t } = useTranslation('dashboard');
  const dispatch = useDispatch();
  const dashboardState = useSelector<IGlobalState, IDashboardState>(state => state.DASHBOARD);

  useEffect(() => {
    dispatch(fetchDashboardDataActionCreator());
  }, [dispatch]);

  if (dashboardState.isFetching) {
    return <div>{t('loading')}</div>;
  }

  if (dashboardState.error) {
    return <div>{t('error')}: {dashboardState.error}</div>;
  }

  return (
    <DashboardContainer>
      <StyledTitle level={2}>{t('dashboard')}</StyledTitle>
      <GridContainer>
        <StatisticsCards />
        <StyledCard title={t('activityFeed')}>
          <ActivityFeed />
        </StyledCard>
      </GridContainer>
      <FullWidthCard title={t('recentTransactions')}>
        <TransactionsTable />
      </FullWidthCard>
    </DashboardContainer>
  );
};

export default Dashboard;
