import 'core-js/stable';
import 'regenerator-runtime/runtime';

import { ConfigProvider, Spin } from 'antd';
import React, { Suspense, useEffect, lazy } from 'react';
import ReactDOM from 'react-dom/client';
import { Provider } from 'react-redux';
import { PersistGate } from 'redux-persist/integration/react';
import 'sanitize.css';
import './index.css';
import { BrowserRouter, Route, Routes, Navigate, useLocation } from 'react-router-dom';
import { ThemeProvider } from 'styled-components';
import { Notification } from './components/Notification';
import GlobalStyle from './lib/globalstyles';
import { darkTheme, lightTheme, mapThemeToAntD } from './lib/theme';
import Dashboard from './pages/Dashboard';
import Login from './pages/Login';
import { UserRegister } from './pages/Login/Register';
import { ResetPassword } from './pages/Login/ResetPassword';
import { store, persistor } from './redux/store';
import reportWebVitals from './reportWebVitals';
import { ForgotPassword } from './pages/Login/ForgotPassword';
import { I18nextProvider } from 'react-i18next';
import i18n from './i18n';
import Layout from './components/Layout';
import AuthWrapper from './components/AuthWrapper';
import { UserLogin } from './pages/Login/UserLogin';
import AccountSettings from './pages/AccountSettings';
import { Design } from './components/Design';
import Channels from './pages/Channels';
import { checkForUpdates } from './utils/versionCheck';
import { Products } from './pages/Products';
import { ProductDetail } from './pages/Products/Detail';
import { useSelector } from 'react-redux';
import { IGlobalState } from './redux/reducers';

const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement);

const App: React.FC = () => {
  const isDarkMode = useSelector((state: IGlobalState) => state.theme.isDarkMode);
  const appliedTheme = isDarkMode ? darkTheme : lightTheme;
  const antDTheme = mapThemeToAntD(appliedTheme);

  useEffect(() => {
    checkForUpdates();
    const intervalId = setInterval(checkForUpdates, 60 * 60 * 1000);
    return () => clearInterval(intervalId);
  }, []);

  useEffect(() => {
    document.body.style.backgroundColor = appliedTheme.colors.background;
  }, [isDarkMode]);

  return (
    <ConfigProvider theme={antDTheme}>
      <ThemeProvider theme={appliedTheme}>
        <I18nextProvider i18n={i18n}>
          <Provider store={store}>
            <PersistGate loading={<Spin size="large" />} persistor={persistor}>
              <BrowserRouter>
                <GlobalStyle />
                <Notification />
                <Suspense fallback={<div>Loading...</div>}>
                  <Routes>
                    <Route path="/" element={<AuthWrapper><Login /></AuthWrapper>}>
                      <Route path="giris" index element={<UserLogin />} />
                      <Route index element={<UserLogin />} />
                      <Route path="kayit-ol" element={<UserRegister />} />
                      <Route path="sifre-sifirlama" element={<ForgotPassword />} />
                      <Route path="reset-password" element={<ResetPassword />} />
                    </Route>
                    <Route 
                      path="/"
                      element={
                        <ProtectedRoute>
                          <Layout />
                        </ProtectedRoute>
                      }
                    >
                      <Route path="dashboard" element={<Dashboard />} />
                      <Route path="products">
                        <Route index element={<Products />} />
                        <Route path=":id" element={<ProductDetail />} />
                      </Route>
                      <Route path="account-settings/*" element={<AccountSettings />} />
                      <Route path="design" element={<Design />} />
                      <Route path="channels/*" element={<Channels />} />
                      <Route path="*" element={<Navigate to="/dashboard" replace />} />
                    </Route>
                    <Route path="*" element={<Navigate to="/" replace />} />
                  </Routes>
                </Suspense>
              </BrowserRouter>
            </PersistGate>
          </Provider>
        </I18nextProvider>
      </ThemeProvider>
    </ConfigProvider>
  );
};

const ProtectedRoute: React.FC<{ children: React.ReactNode }> = ({ children }) => {
  const user = store.getState().LOGIN.user;
  const location = useLocation();

  if (!user) {
    return <Navigate to="/giris" state={{ from: location }} replace />;
  }

  return <>{children}</>;
};

if ('serviceWorker' in navigator) {
  window.addEventListener('load', () => {
    navigator.serviceWorker.register('/service-worker.js').then(
      registration => {
        console.log('SW registered: ', registration);
      },
      error => {
        console.log('SW registration failed: ', error);
      },
    );
  });
}

const AppWrapper: React.FC = () => {
  return (
    <Provider store={store}>
      <App />
    </Provider>
  );
};

root.render(
  <React.StrictMode>
    <AppWrapper />
  </React.StrictMode>
);
reportWebVitals();
