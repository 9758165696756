import styled from 'styled-components';
import { Card, Typography } from 'antd';

const { Title } = Typography;

export const DashboardContainer = styled.div`
  display: grid;
  gap: 24px;
  padding: 24px;
  background-color: ${({ theme }) => theme.colors.background};

  @media (max-width: ${({ theme }) => theme.breakpoints.tablet}) {
    padding: 16px;
    gap: 16px;
  }
`;

export const StyledCard = styled(Card)`
  border-radius: 8px;
  box-shadow: ${({ theme }) => theme.shadows.small};
  background-color: ${({ theme }) => theme.colors.cardBackground};

  .ant-card-head-title {
    font-size: ${({ theme }) => theme.fontSizes.medium};
    color: ${({ theme }) => theme.colors.text};
  }

  .ant-card-body {
    padding: 20px;
  }
`;

export const StyledTitle = styled(Title)`
  color: ${({ theme }) => theme.colors.text};
  margin-bottom: 16px !important;
`;

export const GridContainer = styled.div`
  display: grid;
  gap: 24px;
  grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));

  @media (max-width: ${({ theme }) => theme.breakpoints.tablet}) {
    grid-template-columns: 1fr;
  }
`;

export const FullWidthCard = styled(StyledCard)`
  grid-column: 1 / -1;
`;
